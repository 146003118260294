import React, { useState } from 'react';
import { Row, Col, Divider, Affix } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InsightsRelatedArticles from './InsightsRelatedArticles';

const StyledInsightsViewPage = styled.div`
  max-width: 1440px;
  margin: auto;

  .side-bar-wrapper {
    position: absolute;
    left: 0;

    .social-media-links {
      font-size: 24px;
      background-color: ${props =>
        `${props.theme.primeBluePalette.original}E6`};
      padding: 24px 12px;

      a {
        color: ${props => props.theme.whitePalette.original};
      }
    }

    @media only screen and (max-width: 991px) {
      display: none;
    }
  }

  .hero-image-section {
    background-image: ${props =>
      `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.28), rgba(0, 0, 0, 0.8)),
      url(${props.coverImage})`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 360px;

    .header-details {
      padding: 0px 8px 32px;

      .styled-section {
        color: ${props => props.theme.whitePalette.original};
        letter-spacing: 1px;
        margin-bottom: 4px;
        font-weight: 600;
      }

      .styled-author {
        color: ${props => props.theme.whitePalette.light};
        font-size: 14px;
      }

      h2 {
        color: ${props => props.theme.primeYellowPalette.original};
        margin-bottom: 4px;
      }
    }
  }

  .content-wrapper {
    padding: 32px 8px;

    p {
      line-height: 26px;
      margin-bottom: 20px;
    }

    .social-media-links {
      font-size: 28px;
      display: flex;

      .styled-icon {
        margin-left: 16px;

        :first-child {
          margin-left: 0;
        }
      }
    }
  }

  .related-articles {
    padding-top: 32px;
  }
  .share-section,.tags-section {
    margin-top: 40px;
    font-weight: 600;
  }
  .tags-content {
    color: #BBBBBB;
  }
`;

const SocialMediaButtons = ({ postUrl, title }) => (
  <div className="social-media-links">
    <div className="styled-icon">
      <a href={`https://www.facebook.com/sharer.php?u=${postUrl}`}>
        <FontAwesomeIcon icon={['fab', 'facebook-square']} />
      </a>
    </div>
    <div className="styled-icon">
      <a href={`https://twitter.com/share?url=${postUrl}&text=${title}`}>
        <FontAwesomeIcon icon={['fab', 'twitter-square']} />
      </a>
    </div>
    <div className="styled-icon">
      <a
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${postUrl}`}
      >
        <FontAwesomeIcon icon={['fab', 'linkedin']} />
      </a>
    </div>
    <div className="styled-icon">
      <a href={`mailto:?Subject=${title}&body=${postUrl}`}>
        <FontAwesomeIcon icon={['fas', 'envelope']} />
      </a>
    </div>
    <div className="styled-icon">
      <a
        href={`hhttp://www.facebook.com/dialog/send?app_id=740425426422111&link=${postUrl}&redirect_uri=${postUrl}`}
      >
        <FontAwesomeIcon icon={['fab', 'facebook-messenger']} />
      </a>
    </div>
  </div>
);

function InsightsViewPageContent({ insight, articles, location }) {
  const [container, setContainer] = useState(null);

  return (
    <StyledInsightsViewPage
      coverImage={`${process.env.IMAGE_ASSETS}/real-estate-insights/${insight.strapiId}/thumb/${insight.thumbnail}`}
      ref={setContainer}
    >
      <Affix
        offsetTop={240}
        className="side-bar-wrapper"
        target={() => container}
      >
        <SocialMediaButtons
          postUrl={location.href}
          title={insight.title}
        />
      </Affix>
      <div className="hero-image-section">
        <Row
          type="flex"
          justify="center"
          align="bottom"
          style={{ height: '100%' }}
        >
          <Col xxl={18} lg={20} md={22} sm={23} xs={23}>
            <Col lg={16} sm={24} xs={24}>
              <div className="header-details">
                <div className="styled-section">
                  {insight.section ? insight.section.toUpperCase() : ''}
                </div>
                <h2>{insight.title}</h2>
                <div className="styled-author">
                  {insight.author} •{' '}
                  {moment(insight.published_date).format('MMMM DD, YYYY')}
                </div>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
      <Row type="flex" justify="center">
        <Col xxl={18} lg={20} md={22} sm={23} xs={23}>
          <Row type="flex" justify="space-between">
            <Col lg={14} sm={24} xs={24}>
              <div className="content-wrapper">
                <div
                  dangerouslySetInnerHTML={{
                    __html: insight.content,
                  }}
                />
                <div className="tags-section">Tags:</div>
                <div className="tags-content">{insight.tags}</div>
                <div className="share-section">Share this post:</div>
                <SocialMediaButtons
                  postUrl={location.href}
                  title={insight.title}
                />
                <Divider />
              </div>
            </Col>
            <Col lg={8} sm={24} xs={24} className="related-articles">
              <h3>LATEST INSIGHTS</h3>
              <InsightsRelatedArticles
                articles={articles}
                newsId={insight.strapiId}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledInsightsViewPage>
  );
}

export default InsightsViewPageContent;
