import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'gatsby';
import styled from 'styled-components';
import moment from 'moment';

const StyledRelatedInsights = styled.div`
  background-image: ${props =>
    `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)),
      url(${props.coverImage})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 12px;
  margin: 0px 8px 16px;

  .styled-section {
    color: ${props => props.theme.whitePalette.original};
    letter-spacing: 1px;
    margin-bottom: 4px;
    font-weight: 600;
  }

  .styled-author {
    color: ${props => props.theme.whitePalette.light};
    font-size: 14px;
  }

  h5 {
    color: ${props => props.theme.primeYellowPalette.original};
    margin-bottom: 4px;
  }
`;

function InsightsRelatedArticles({ articles, newsId }) {
  const relatedArticles = articles.filter(
    value => value.strapiId !== newsId
  );
  return (
    <Row type="flex" justify="center">
      {relatedArticles.slice(0, 4).map(data => (
        <Col lg={24} md={8} sm={18} xs={24} key={data.strapiId}>
          <Link to={`/insights/articles/${data.url_link}`}>
            <StyledRelatedInsights
              coverImage={`${process.env.IMAGE_ASSETS}/real-estate-insights/${data.strapiId}/thumb/${data.thumbnail}`}
            >
              <div className="styled-section">{data.section.toUpperCase()}</div>
              <h5>{data.title}</h5>
              <div className="styled-author">
                {data.author} •{' '}
                {moment(data.published_date).format('MMMM DD, YYYY')}
              </div>
            </StyledRelatedInsights>
          </Link>
        </Col>
      ))}
    </Row>
  );
}

export default InsightsRelatedArticles;
