import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import InsightsViewPageContent from '../components/insights/InsightsViewPageContent';

const StyledDivWrapper = styled.div`
  padding-top: 89px;
`;

function InsightsViewPage({ pageContext, location }) {
  const data = useStaticQuery(graphql`
    {
      allStrapiRealEstateInsight(sort: { fields: published_date, order: DESC }) {
        nodes {
          author
          description
          strapiId
          url_link
          title
          tags
          thumbnail
          section
          published_date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  `);

  const articles = data.allStrapiRealEstateInsight.nodes;
  return (
    <Layout>
      <SEO
        title={pageContext.insight.title}
        description={pageContext.insight.description}
        meta={[
          {
            property: 'og:image',
            content: `${process.env.IMAGE_ASSETS}/real-estate-insights/${pageContext.insight.strapiId}/thumb/${pageContext.insight.thumbnail}`,
          },
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content: pageContext.insight.tags,
          },
        ]}
      />
      <StyledDivWrapper>
        <InsightsViewPageContent
          insight={pageContext.insight}
          articles={articles}
          location={location}
        />
      </StyledDivWrapper>
    </Layout>
  );
}

export default InsightsViewPage;
